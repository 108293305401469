import React, { Children, useState, useEffect, useRef } from 'react';
import { DataProvider } from '@looker/components-data';
import { Query, Visualization } from '@looker/visualizations';
import { ComponentsProvider } from '@looker/components'
import html2canvas from 'html2canvas';
import { fetchQueryResults } from '../api/lookerApi';


const PivotLabelComponent = ({ data, fields, config, totals, pivots, children, graphType }) => {

  if (!config.series) {
    return <div>Error config series no found... </div>;
  }
  Object.keys(config.series).forEach((key, _) => {
    config.series[key].label = key.split(' - ')[0];
  })

  config.type = graphType;

  if (Children.count(children) >= 1) {
    return (
      <>
        {Children.map(children, child => {
          return React.isValidElement(child)
            ? React.cloneElement(child, {
              config: config,
              data: data,
              fields: fields,
              pivots: pivots,
              totals: totals,
            })
            : child
        })}
      </>
    )
  } else {
    console.warn(('No children passed to PivotLabelComponent component'))
    return null
  }
};


const VisualizationComponent = ({
  sdk,
  queryId,
  graphType,
  currentSelection,
  onImageCapture,
  onUpdateBlockText
}) => {

  const [data, setData] = useState(null);
  const visualizationRef = useRef(null);

  useEffect(() => {
    if (queryId && graphType && currentSelection) {
      // 等待渲染完成後捕獲圖像
      // onUpdateBlockText(currentSelection, "等待圖片產出 ...", "#C4554D")
      const captureTimeout = setTimeout(() => {
        if (visualizationRef.current) {
          html2canvas(visualizationRef.current).then(canvas => {
            const base64Image = canvas.toDataURL('image/png');
            onImageCapture(base64Image);
          });
        }
      }, 5000); // 等待 1 秒以確保渲染完成

      return () => clearTimeout(captureTimeout);
    }
  }, [queryId, graphType, onImageCapture, onUpdateBlockText]);


  if (!sdk) {
    return <div>Loading... </div>;
  }


  if (!queryId || !graphType) {
    return <div></div>
  }

  console.log('queryId: ', queryId)
  console.log('graphType: ', graphType)

  let queryRender;

  if (graphType !== 'pie') {
    queryRender =
      <Query query={queryId} >
        <PivotLabelComponent graphType={graphType} >
          <Visualization />
        </PivotLabelComponent >
      </Query>;
  } else {
    queryRender =
      <Query query={queryId} config={{ type: graphType }}>
        <Visualization />
      </Query>;
  }

  return (
    <>
      {/* <h2> Get Started with Looker Visualization componentes</h2> */}
      <div ref={visualizationRef} 
      style={{
        position: 'absolute',
        left: '-9999px',
        top: '0',
        width: '100%'
      }}>
        <ComponentsProvider>
          <DataProvider sdk={sdk} >
            {queryRender}
          </DataProvider>
        </ComponentsProvider>
      </div>
    </>
  );
};

export default VisualizationComponent;