import React, { useEffect, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

const CustomScrollbar = ({ children }) => {
  const scrollbarRef = useRef(null);

  const options = {
    wheelSpeed: 1,
    wheelPropagation: true,
    minScrollbarLength: 20
  };

  useEffect(() => {
    // 確保在執行任何 DOM 操作前有一個小的延遲
    const timeoutId = setTimeout(() => {
      if (scrollbarRef.current) {
        const applyStyles = () => {
          const thumbElement = scrollbarRef.current?.querySelector('.ps__thumb-y');
          if (thumbElement) {
            Object.assign(thumbElement.style, {
              width: '18px',
              backgroundColor: 'rgba(135, 151, 166, 0.70)',
              borderRadius: '0px',
              transform: 'translateY(20px)'
            });
          }
        };

        applyStyles();
        
        const observer = new MutationObserver(applyStyles);
        observer.observe(scrollbarRef.current, { childList: true, subtree: true });

        return () => observer.disconnect();
      }
    }, 100); // 100ms 的延遲

    return () => clearTimeout(timeoutId);
  }, []);
  
    return (
      <PerfectScrollbar
        options={options}
        containerRef={elem => {
          scrollbarRef.current = elem;
        }}
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        {children}
      </PerfectScrollbar>
    );
};

export default CustomScrollbar;