import { useState } from 'react';

import SideBar from './sideBar';
import HorizontalBar from './horizontalBar';
import { CustomSelectOption, CustomRadioGroup } from './customInput';
import { withTranslation } from '../utils/i18n';

import style from "./shared.module.scss";

const Setting = ({ t }) => {
    const [selectedOption1, setSelectedOption1] = useState('simple');
    const [selectedOption2, setSelectedOption2] = useState('');

    return (
      <div className='w-full h-full grid grid-cols-4 lg:grid-cols-7'>
        <div className='col-span-1'>
          <SideBar />
        </div>
        <div className='col-span-6 col-start-2'>
          <HorizontalBar />
          <div className='pt-[110px] pb-[36px] ps-[68px] pe-[20px]'>
            <div>
                <h3 className={`${style.title}`}>{t('setting.language.label')}</h3>
                <CustomSelectOption options={['繁體中文', '简体中文', 'English']} />
            </div>
            <div className='mt-[36px]'>
                <h3 className={`${style.title}`}>{t('setting.reasoning.label')}</h3>
                <h4 className={`${style.subtitle} mt-[13px]`}>{t('setting.reasoning.section1.label')}</h4>
                <CustomRadioGroup options={[t('setting.reasoning.section1.option.simple'), t('setting.reasoning.section1.option.complex'), t('setting.reasoning.section1.option.react'), t('setting.reasoning.section1.option.rewoo')]} name="reasoning" onChange={setSelectedOption1} selectedValue={selectedOption1} />
                <p className='mt-[30px] text-[#69747F] w-[70%]'>{t('setting.reasoning.section1.note')}</p>
                <h4 className={`${style.subtitle}`}>{t('setting.reasoning.section2.label')}</h4>
                <CustomRadioGroup options={[t('setting.reasoning.section2.option.highlight')]} name="citation" onChange={setSelectedOption2} selectedValue={selectedOption2} allowDeselect={true} />
            </div>
            <div>
                <h3 className={`${style.title}`}>{t('setting.systemPrompt.label')}</h3>
                <textarea className={`${style.textarea} w-2/3`} rows={10}></textarea>
            </div>
            <div>
                <h3 className={`${style.title}`}>{t('setting.qaPrompt.label')}</h3>
                <textarea className={`${style.textarea} w-2/3`} rows={10}></textarea>
            </div>
            <div>
                <h3 className={`${style.title}`}>{t('setting.number.label')}</h3>
                <textarea className={`${style.textarea} w-2/3`} rows={10}></textarea>
            </div>
          </div>
        </div>
      </div>
    )
}

export default withTranslation(Setting);