import React, { createContext, useState, useContext, useEffect } from 'react';
import NewAuthService from "./service/auth-service";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await NewAuthService.getCurrentUser();
        const { token, name, email } = res.data;
        setCurrentUser({ token, name, email });
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    getUser();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);