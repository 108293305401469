import React, { useState, useEffect } from 'react';
import { MoreHorizontal, Download, FileDown, Trash2 } from 'lucide-react';
import { html as beautifyHtml } from 'js-beautify';

import { useDeleteProject } from '../utils/customHooks/useDeleteProject';
import { applyHighlightsToHtml } from '../utils/handleHighlightText';

const ExportMenu = ({ onExport, onDelete, title, editor }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

    const handleMoreClick = (e) => {
        e.stopPropagation();
        const rect = e.currentTarget.getBoundingClientRect();
        setMenuPosition({ x: rect.left, y: rect.bottom + 5 });
        setShowMenu(!showMenu);
    };

    useEffect(() => {
        const handleClickOutside = () => {
            setShowMenu(false);
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);


    // function getTextContent(element) {
    //   return element.textContent || '';
    // }    

    // function addDataBlockIds(jsonContent, htmlString) {
    //   const parser = new DOMParser();
    //   const doc = parser.parseFromString(htmlString, 'text/html');
    //   const formatTags = ['b', 'i', 's', 'u', 'code'];
      
    //   Object.values(jsonContent).forEach(block => {
    //     block.value.forEach(valueItem => {
    //       if (!valueItem.children || !valueItem.children.some(child => child.highlight)) return;
    
    //       const targetText = valueItem.children.map(child => child.text).join('');
    //       if (!targetText) return;
    
    //       // 函數：遞迴搜索並處理元素
    //       function processElement(element) {
    //         // 獲取當前元素的純文本內容
    //         const elementText = getTextContent(element);
            
    //         // 如果當前元素包含目標文本
    //         if (elementText.includes(targetText)) {
    //           // 檢查是否是最小的包含完整目標文本的元素
    //           const childElements = Array.from(element.children);
    //           let isSmallestContainer = true;
              
    //           for (const child of childElements) {
    //             const childText = getTextContent(child);
    //             if (childText.includes(targetText)) {
    //               isSmallestContainer = false;
    //               processElement(child);
    //               break;
    //             }
    //           }
              
    //           if (isSmallestContainer) {
    //             // 檢查此元素是否是格式標籤
    //             if (formatTags.includes(element.tagName.toLowerCase())) {
    //               // 如果是格式標籤，我們需要檢查父元素
    //               if (element.parentElement) {
    //                 element.parentElement.setAttribute('data-block-id', valueItem.id);
    //               }
    //             } else {
    //               element.setAttribute('data-block-id', valueItem.id);
    //             }
    //           }
    //         } else {
    //           // 如果當前元素不包含完整的目標文本，遞迴檢查其子元素
    //           Array.from(element.children).forEach(child => {
    //             processElement(child);
    //           });
    //         }
    //       }
    
    //       // 從 body 開始處理所有元素
    //       processElement(doc.body);
    //     });
    //   });
    
    //   return doc.body.innerHTML;
    // }
    
    // function applyHighlightsToHtml(jsonContent, htmlString) {
    //   const htmlWithIds = addDataBlockIds(jsonContent, htmlString);
    //   const parser = new DOMParser();
    //   const doc = parser.parseFromString(htmlWithIds, 'text/html');
    
    //   Object.values(jsonContent).forEach(block => {
    //     block.value.forEach(valueItem => {
    //       if (!valueItem.children || !valueItem.children.some(child => child.highlight)) return;
    
    //       const elements = doc.querySelectorAll(`[data-block-id="${valueItem.id}"]`);
    //       elements.forEach(element => {
    //         try {
    //           // 收集所有文本節點
    //           const textNodes = [];
    //           let totalIndex = 0;
              
    //           const walker = document.createTreeWalker(
    //             element,
    //             NodeFilter.SHOW_TEXT,
    //             null
    //           );
    
    //           let node;
    //           while (node = walker.nextNode()) {
    //             const text = node.textContent || '';
    //             textNodes.push({
    //               node,
    //               text,
    //               startIndex: totalIndex
    //             });
    //             totalIndex += text.length;
    //           }
    
    //           // 為了調試，我們可以記錄一下文本節點的情況
    //           console.log('Text nodes:', textNodes.map(n => ({
    //             text: n.text,
    //             startIndex: n.startIndex
    //           })));
    
    //           // 重建完整的文本內容用於驗證
    //           const fullText = textNodes.map(n => n.text).join('');
    //           console.log('Full text:', fullText);
    
    //           // 創建高亮操作列表
    //           const highlightOperations = [];
    
    //           let currentTextIndex = 0;
    //           valueItem.children.forEach(child => {
    //             if (!child.highlight) {
    //               currentTextIndex += child.text.length;
    //               return;
    //             }
    
    //             // 找到包含當前文本的節點
    //             let found = false;
    //             for (let i = 0; i < textNodes.length; i++) {
    //               const nodeInfo = textNodes[i];
    //               const nodeEndIndex = nodeInfo.startIndex + nodeInfo.text.length;
    
    //               if (nodeInfo.startIndex <= currentTextIndex && nodeEndIndex > currentTextIndex) {
    //                 const startOffset = currentTextIndex - nodeInfo.startIndex;
    //                 const availableLength = Math.min(
    //                   child.text.length,
    //                   nodeInfo.text.length - startOffset
    //                 );
    
    //                 // 確保偏移量不超過節點的文本長度
    //                 if (startOffset <= nodeInfo.text.length && 
    //                     startOffset + availableLength <= nodeInfo.text.length) {
    //                   highlightOperations.push({
    //                     nodeIndex: i,
    //                     startOffset,
    //                     endOffset: startOffset + availableLength,
    //                     style: child.highlight
    //                   });
    //                   found = true;
    //                 }
    //                 break;
    //               }
    //             }
    
    //             if (!found) {
    //               console.warn(`Could not find text node for highlighting at index ${currentTextIndex}`);
    //             }
    //             currentTextIndex += child.text.length;
    //           });
    
    //           // 應用高亮
    //           highlightOperations.reverse().forEach(op => {
    //             if (op.nodeIndex >= 0 && op.nodeIndex < textNodes.length) {
    //               const textNode = textNodes[op.nodeIndex].node;
                  
    //               // 再次驗證偏移量
    //               if (op.startOffset >= 0 && 
    //                   op.endOffset <= textNode.length && 
    //                   op.startOffset < op.endOffset) {
    //                 try {
    //                   const range = document.createRange();
    //                   range.setStart(textNode, op.startOffset);
    //                   range.setEnd(textNode, op.endOffset);
    
    //                   const span = doc.createElement('span');
    //                   if (op.style.color) {
    //                     span.style.color = op.style.color;
    //                   }
    //                   if (op.style.backgroundColor) {
    //                     span.style.backgroundColor = op.style.backgroundColor;
    //                   }
    
    //                   range.surroundContents(span);
    //                 } catch (e) {
    //                   console.error('Error applying highlight:', e);
    //                 }
    //               } else {
    //                 console.warn('Invalid offset values:', op);
    //               }
    //             }
    //           });
    //         } catch (e) {
    //           console.error('Error processing element:', e);
    //         }
    //       });
    //     });
    //   });
    
    //   return doc.body.innerHTML;
    // }

    const exportPDF = async () => {
        const rawContent = onExport('pdf');
        if (editor) {
            console.log("jsonContent: ", editor.getEditorValue());
        }

        const applyHighlightsResult = applyHighlightsToHtml(editor.getEditorValue(), rawContent);

        const beautifyOptions = {
            indent_size: 2,
            max_preserve_newlines: 1,
            preserve_newlines: true,
            wrap_line_length: 0
        };
        
        const styleSheet = `
            <style>
                h1 { font-size: 2.25rem; font-weight: bold; margin-bottom: 0.5rem; }
                h2 { font-size: 1.875rem; font-weight: bold; margin-bottom: 0.5rem; }
                h3 { font-size: 1.5rem; font-weight: bold; margin-bottom: 0.5rem; }
                p { margin-bottom: 1rem; }
                ul, ol { margin-left: 2rem; margin-bottom: 1rem; }
                blockquote { 
                    border-left: 3px solid #ccc;
                    margin-left: 0;
                    padding-left: 1rem;
                }
            </style>
        `;
        
        // const beautifiedContent = beautifyHtml(rawContent, beautifyOptions);

        const beautifiedContent = applyHighlightsResult;
        const contentWithStyles = `
            <!DOCTYPE html>
            <html>
                <head>${styleSheet}</head>
                <body>
                    <div class="title"}>
                        ${title}
                    </div>
                    <div class="dividedLine"></div>
                    ${beautifiedContent}
                </body>
            </html>
        `;
        
        const element = document.createElement('div');
        element.innerHTML = contentWithStyles;
        
        const opt = {
            margin: 1,
            filename: 'document.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        
        try {
            const html2pdf = (await import('html2pdf.js')).default;
            await html2pdf().set(opt).from(element).save();
            setShowMenu(false);
        } catch (error) {
            console.error('PDF 匯出失敗:', error);
        }
    };

    const exportMarkdown = () => {
        const content = onExport('markdown');
        const titleMd = `# ${title}\n\n`;
        const fullContent = titleMd + content;
        const blob = new Blob([fullContent], { type: 'text/markdown' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'document.md';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setShowMenu(false);
    };

    return (
        <div className="fixed end-5">
            <button 
                variant="ghost" 
                className="hover:bg-gray-200 p-2"
                onClick={handleMoreClick}
            >
                <MoreHorizontal size={30} />
            </button>

            {showMenu && (
                <div 
                    className="absolute bg-white shadow-lg rounded-md py-1 z-50 right-0 text-nowrap"
                    // style={{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px` }}
                >
                    <button 
                        className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center gap-2"
                        onClick={exportPDF}
                    >
                        <FileDown size={16} />
                        匯出 PDF
                    </button>
                    <button 
                        className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center gap-2"
                        onClick={exportMarkdown}
                    >
                        <Download size={16} />
                        匯出 Markdown
                    </button>
                    {/* <button 
                        className="w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100 flex items-center gap-2"
                        onClick={() => {
                            onDelete();
                            setShowMenu(false);
                        }}
                    >
                        <Trash2 size={16} />
                        刪除
                    </button> */}
                </div>
            )}
        </div>
    );
};

export default ExportMenu;