import axios from "axios";
const BASIC_API = process.env.REACT_APP_BASIC_API + '/Account';
console.log(BASIC_API);

class AuthService {
    getCurrentUser() {
        let token;
        JSON.parse(localStorage.getItem("biodnd_ai_user"))
        ? token = JSON.parse(localStorage.getItem("biodnd_ai_user")).token
        : token = "";
        console.log(`token: ${token}`);

        return axios.get(`${BASIC_API}`, {
            headers: {Authorization: `Bearer ${token}`}
        });
    }

    logout() {
        localStorage.clear();
    }
}

const NewAuthService = new AuthService();
export default NewAuthService;