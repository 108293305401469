export default function CustomTable({ headers, data, columnWidths }) {
    // 生成 grid-template-columns 的值
    const gridTemplateColumns = columnWidths.join(' ');
    const minRows = 8;
    const filledData = [...data, ...Array(Math.max(0, minRows - data.length)).fill(Array(headers.length).fill(''))];
  
  
    return (
      <div className="custom-table">
        <div className="table-header">
          {headers.map((header, index) => (
            <div key={index} className={`header-cell header-cell-${index}`}>
                {header}
            </div>
          ))}
        </div>
        <div className="table-body">
          {filledData.map((row, rowIndex) => (
            <div key={rowIndex} className="table-row">
              {row.map((cell, cellIndex) => (
                <div key={cellIndex} className={`table-cell cell-${cellIndex}`}>
                  <div className="cell-content">{cell}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <style jsx>{`
          .custom-table {
            border: 1px solid #c9c9c9;
            border-radius: 8px;
            overflow: hidden;
            width: 100%;
          }
          .table-header, .table-row {
            display: grid;
            grid-template-columns: ${gridTemplateColumns};
          }
          .table-header {
          }
          .header-cell {
            padding: 10px 12px;
            border-bottom: 1px solid #c9c9c9;
            border-right: 1px solid #c9c9c9;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .header-cell-0, .cell-0 {
            text-align: center;
          }
          .table-body {
            display: grid;
            grid-template-columns: 1fr;
            max-height: 293px;
            overflow-y: auto;
          }
          .table-row {
            border-bottom: 1px solid #c9c9c9;
            height: 31px;
          }
          .table-row:last-child {
            border-bottom: none;
          }
          .table-cell {
            padding: 8px 12px;
            overflow: hidden;
            border-right: 1px solid #c9c9c9;
          }
          .cell-content {
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            height: 100%;
            display: flex;
            align-items: center;
          }
          .cell-content::-webkit-scrollbar {
            height: 3px;
          }
          .cell-content::-webkit-scrollbar-thumb {
            background-color: #cbd5e0;
          }
          /* 響應式設計 */
          @media (max-width: 640px) {
            .table-header, .table-row {
              grid-template-columns: 1fr;
            }
            .header-cell, .table-cell {
              border-bottom: 1px solid #e2e8f0;
            }
            .header-cell:last-child {
              border-bottom: 2px solid #e2e8f0;
            }
            .table-row:last-child .table-cell:last-child {
              border-bottom: none;
            }
          }
        `}</style>
      </div>
    );
};