// function getTextContent(element) {
//     return element.textContent || '';
//   }    

//   function addDataBlockIds(jsonContent, htmlString) {
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(htmlString, 'text/html');
//     const formatTags = ['b', 'i', 's', 'u', 'code'];
    
//     Object.values(jsonContent).forEach(block => {
//       block.value.forEach(valueItem => {
//         if (!valueItem.children || !valueItem.children.some(child => child.highlight)) return;
  
//         const targetText = valueItem.children.map(child => child.text).join('');
//         if (!targetText) return;
  
//         // 函數：遞迴搜索並處理元素
//         function processElement(element) {
//           // 獲取當前元素的純文本內容
//           const elementText = getTextContent(element);
          
//           // 如果當前元素包含目標文本
//           if (elementText.includes(targetText)) {
//             // 檢查是否是最小的包含完整目標文本的元素
//             const childElements = Array.from(element.children);
//             let isSmallestContainer = true;
            
//             for (const child of childElements) {
//               const childText = getTextContent(child);
//               if (childText.includes(targetText)) {
//                 isSmallestContainer = false;
//                 processElement(child);
//                 break;
//               }
//             }
            
//             if (isSmallestContainer) {
//               // 檢查此元素是否是格式標籤
//               if (formatTags.includes(element.tagName.toLowerCase())) {
//                 // 如果是格式標籤，我們需要檢查父元素
//                 if (element.parentElement) {
//                   element.parentElement.setAttribute('data-block-id', valueItem.id);
//                 }
//               } else {
//                 element.setAttribute('data-block-id', valueItem.id);
//               }
//             }
//           } else {
//             // 如果當前元素不包含完整的目標文本，遞迴檢查其子元素
//             Array.from(element.children).forEach(child => {
//               processElement(child);
//             });
//           }
//         }
  
//         // 從 body 開始處理所有元素
//         processElement(doc.body);
//       });
//     });
  
//     return doc.body.innerHTML;
//   }
  
// export default function applyHighlightsToHtml(jsonContent, htmlString) {
//     const htmlWithIds = addDataBlockIds(jsonContent, htmlString);
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(htmlWithIds, 'text/html');
  
//     Object.values(jsonContent).forEach(block => {
//       block.value.forEach(valueItem => {
//         if (!valueItem.children || !valueItem.children.some(child => child.highlight)) return;
  
//         const elements = doc.querySelectorAll(`[data-block-id="${valueItem.id}"]`);
//         elements.forEach(element => {
//           try {
//             // 收集所有文本節點
//             const textNodes = [];
//             let totalIndex = 0;
            
//             const walker = document.createTreeWalker(
//               element,
//               NodeFilter.SHOW_TEXT,
//               null
//             );
  
//             let node;
//             while (node = walker.nextNode()) {
//               const text = node.textContent || '';
//               textNodes.push({
//                 node,
//                 text,
//                 startIndex: totalIndex
//               });
//               totalIndex += text.length;
//             }
  
//             // 為了調試，我們可以記錄一下文本節點的情況
//             console.log('Text nodes:', textNodes.map(n => ({
//               text: n.text,
//               startIndex: n.startIndex
//             })));
  
//             // 重建完整的文本內容用於驗證
//             const fullText = textNodes.map(n => n.text).join('');
//             console.log('Full text:', fullText);
  
//             // 創建高亮操作列表
//             const highlightOperations = [];
  
//             let currentTextIndex = 0;
//             valueItem.children.forEach(child => {
//               if (!child.highlight) {
//                 currentTextIndex += child.text.length;
//                 return;
//               }
  
//               // 找到包含當前文本的節點
//               let found = false;
//               for (let i = 0; i < textNodes.length; i++) {
//                 const nodeInfo = textNodes[i];
//                 const nodeEndIndex = nodeInfo.startIndex + nodeInfo.text.length;
  
//                 if (nodeInfo.startIndex <= currentTextIndex && nodeEndIndex > currentTextIndex) {
//                   const startOffset = currentTextIndex - nodeInfo.startIndex;
//                   const availableLength = Math.min(
//                     child.text.length,
//                     nodeInfo.text.length - startOffset
//                   );
  
//                   // 確保偏移量不超過節點的文本長度
//                   if (startOffset <= nodeInfo.text.length && 
//                       startOffset + availableLength <= nodeInfo.text.length) {
//                     highlightOperations.push({
//                       nodeIndex: i,
//                       startOffset,
//                       endOffset: startOffset + availableLength,
//                       style: child.highlight
//                     });
//                     found = true;
//                   }
//                   break;
//                 }
//               }
  
//               if (!found) {
//                 console.warn(`Could not find text node for highlighting at index ${currentTextIndex}`);
//               }
//               currentTextIndex += child.text.length;
//             });
  
//             // 應用高亮
//             highlightOperations.reverse().forEach(op => {
//               if (op.nodeIndex >= 0 && op.nodeIndex < textNodes.length) {
//                 const textNode = textNodes[op.nodeIndex].node;
                
//                 // 再次驗證偏移量
//                 if (op.startOffset >= 0 && 
//                     op.endOffset <= textNode.length && 
//                     op.startOffset < op.endOffset) {
//                   try {
//                     const range = document.createRange();
//                     range.setStart(textNode, op.startOffset);
//                     range.setEnd(textNode, op.endOffset);
  
//                     const span = doc.createElement('span');
//                     if (op.style.color) {
//                       span.style.color = op.style.color;
//                     }
//                     if (op.style.backgroundColor) {
//                       span.style.backgroundColor = op.style.backgroundColor;
//                     }
  
//                     range.surroundContents(span);
//                   } catch (e) {
//                     console.error('Error applying highlight:', e);
//                   }
//                 } else {
//                   console.warn('Invalid offset values:', op);
//                 }
//               }
//             });
//           } catch (e) {
//             console.error('Error processing element:', e);
//           }
//         });
//       });
//     });
  
//     return doc.body.innerHTML;
//   }





import { generateId } from "@yoopta/editor";

// 輔助函數：安全地獲取文本內容
function safeGetTextContent(element) {
  return element ? (element.textContent || '').trim() : '';
}

// 輔助函數：解析高亮樣式
function parseHighlightStyle(styleString) {
  if (!styleString) return null;
  
  const style = {};
  const colorMatch = styleString.match(/color:\s*(.*?)(?:;|$)/);
  const bgColorMatch = styleString.match(/background-color:\s*(.*?)(?:;|$)/);
  
  if (colorMatch) style.color = colorMatch[1].trim();
  if (bgColorMatch) style.backgroundColor = bgColorMatch[1].trim();
  
  return Object.keys(style).length > 0 ? style : null;
}

// 添加數據塊 ID
function addDataBlockIds(jsonContent, htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const formatTags = ['b', 'i', 's', 'u', 'code'];
  
  Object.values(jsonContent).forEach(block => {
    block.value.forEach(valueItem => {
      if (!valueItem.children || !valueItem.children.some(child => child.highlight)) return;

      const targetText = valueItem.children.map(child => child.text).join('');
      if (!targetText) return;

      function findAndMarkElement(element) {
        const elementText = safeGetTextContent(element);
        
        if (elementText.includes(targetText)) {
          let closestContainer = element;
          
          // 找到最小的包含完整目標文本的容器
          Array.from(element.children).some(child => {
            if (safeGetTextContent(child).includes(targetText)) {
              closestContainer = child;
              return true;
            }
            return false;
          });
          
          // 處理格式標籤的特殊情況
          if (formatTags.includes(closestContainer.tagName.toLowerCase())) {
            closestContainer = closestContainer.parentElement || closestContainer;
          }
          
          closestContainer.setAttribute('data-block-id', valueItem.id);
          return true;
        }
        
        return Array.from(element.children).some(findAndMarkElement);
      }

      findAndMarkElement(doc.body);
    });
  });

  return doc.body.innerHTML;
}

export function applyHighlightsToHtml(jsonContent, htmlString) {
    const htmlWithIds = addDataBlockIds(jsonContent, htmlString);
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlWithIds, 'text/html');
  
    Object.values(jsonContent).forEach(block => {
      block.value.forEach(valueItem => {
        if (!valueItem.children || !valueItem.children.some(child => child.highlight)) return;
  
        const elements = doc.querySelectorAll(`[data-block-id="${valueItem.id}"]`);
        elements.forEach(element => {
          try {
            // 第一步：收集所有文本節點
            const textNodes = [];
            const walker = document.createTreeWalker(
              element,
              NodeFilter.SHOW_TEXT,
              null
            );
            
            let node;
            while (node = walker.nextNode()) {
              textNodes.push(node);
            }
  
            // 第二步：準備高亮操作
            const operations = [];
            let currentPosition = 0;
            
            valueItem.children.forEach(child => {
              if (child.highlight) {
                operations.push({
                  start: currentPosition,
                  end: currentPosition + child.text.length,
                  highlight: child.highlight
                });
              }
              currentPosition += child.text.length;
            });
  
            // 第三步：應用高亮
            operations.sort((a, b) => b.start - a.start).forEach(op => {
              let currentTextPosition = 0;
              let remainingStart = op.start;
              let remainingLength = op.end - op.start;
  
              for (let i = 0; i < textNodes.length && remainingLength > 0; i++) {
                const textNode = textNodes[i];
                const textLength = textNode.length;
  
                if (currentTextPosition + textLength <= remainingStart) {
                  currentTextPosition += textLength;
                  continue;
                }
  
                const nodeStart = Math.max(0, remainingStart - currentTextPosition);
                const nodeEnd = Math.min(textLength, nodeStart + remainingLength);
                
                if (nodeStart < nodeEnd) {
                  const range = doc.createRange();
                  range.setStart(textNode, nodeStart);
                  range.setEnd(textNode, nodeEnd);
  
                  const span = doc.createElement('span');
                  if (op.highlight.color) span.style.color = op.highlight.color;
                  if (op.highlight.backgroundColor) span.style.backgroundColor = op.highlight.backgroundColor;
  
                  try {
                    range.surroundContents(span);
                    
                    // 更新文本節點列表，因為我們可能創建了新的節點
                    textNodes.splice(i + 1, 0, ...Array.from(span.childNodes));
                  } catch (e) {
                    console.warn('Failed to surround contents, falling back to node splitting', e);
                    
                    // 後備方案：手動分割節點
                    const beforeText = textNode.textContent.slice(0, nodeStart);
                    const highlightText = textNode.textContent.slice(nodeStart, nodeEnd);
                    const afterText = textNode.textContent.slice(nodeEnd);
                    
                    const fragment = doc.createDocumentFragment();
                    if (beforeText) fragment.appendChild(doc.createTextNode(beforeText));
                    span.textContent = highlightText;
                    fragment.appendChild(span);
                    if (afterText) fragment.appendChild(doc.createTextNode(afterText));
                    
                    textNode.parentNode.replaceChild(fragment, textNode);
                    
                    // 更新文本節點列表
                    const newNodes = [
                      ...(beforeText ? [doc.createTextNode(beforeText)] : []),
                      ...Array.from(span.childNodes),
                      ...(afterText ? [doc.createTextNode(afterText)] : [])
                    ];
                    textNodes.splice(i, 1, ...newNodes);
                  }
  
                  remainingLength -= (nodeEnd - nodeStart);
                  remainingStart = currentTextPosition + nodeEnd;
                }
  
                currentTextPosition += textLength;
              }
            });
          } catch (e) {
            console.error('Error processing element:', e);
          }
        });
      });
    });
  
    return doc.body.innerHTML;
  }


// HTML 轉 JSON
export function convertHtmlToJson(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const result = {};

  // 映射 HTML 標籤到對應的區塊類型
  const tagToTypeMapping = {
    'div': { blockType: 'Paragraph', valueType: 'paragraph' },
    'h1': { blockType: 'HeadingOne', valueType: 'heading-one' },
    'h2': { blockType: 'HeadingTwo', valueType: 'heading-two' },
    'h3': { blockType: 'HeadingThree', valueType: 'heading-three' },
    'ul': { blockType: 'BulletedList', valueType: 'bulleted-list' }
  };

  // 處理文本節點
  function processTextNode(node) {
    const result = [];
    
    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent.trim();
      if (text) result.push({ text });
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node;
      
      if (element.tagName.toLowerCase() === 'span') {
        const highlight = parseHighlightStyle(element.getAttribute('style'));
        const text = element.textContent;
        result.push(highlight ? { text, highlight } : { text });
      } else {
        Array.from(element.childNodes).forEach(child => {
          result.push(...processTextNode(child));
        });
      }
    }
    
    return result;
  }

  // 處理頂層區塊元素
  doc.body.childNodes.forEach((blockNode) => {
    if (blockNode.nodeType === Node.ELEMENT_NODE) {
      const blockElement = blockNode;
      const tagName = blockElement.tagName.toLowerCase();
      const types = tagToTypeMapping[tagName] || tagToTypeMapping['div'];
      
      const uuid = generateId();
      const valueItem = {
        id: generateId(),
        type: types.valueType,
        children: processTextNode(blockElement),
        props: {
          nodeType: 'block'
        }
      };

      // 提取 meta 信息
      const meta = {};
      Array.from(blockElement.attributes).forEach(attr => {
        if (attr.name.startsWith('data-meta-')) {
          const metaKey = attr.name.replace('data-meta-', '');
          meta[metaKey] = attr.value;
        }
      });

      const contentBlock = {
        id: uuid,
        type: types.blockType,
        meta,
        value: [valueItem]
      };

      result[uuid] = contentBlock;
    }
  });

  return result;
}



export function OptimizedJsonHighlightSync(styledContent, unstyledContent) {
  const addOrderToMeta = (content) => {
    let order = 0;
    return Object.fromEntries(
      Object.entries(content).map(([key, value]) => {
        if (!value.meta) {
          value.meta = {};
        }
        value.meta.order = order++;
        return [key, value];
      })
    );
  };

  const styledWithOrder = addOrderToMeta(styledContent);
  const unstyledWithOrder = addOrderToMeta(unstyledContent);

  console.log(styledWithOrder);
  console.log(unstyledWithOrder);

  const syncHighlights = () => {
    const newUnstyledContent = JSON.parse(JSON.stringify(unstyledWithOrder));
    console.log(newUnstyledContent);

    Object.values(styledWithOrder).forEach((styledValue) => {
      if (styledValue.value) {
        console.log("styledValue has value property")
        styledValue.value.forEach((styledItem) => {
          if (styledItem.children) {
            console.log("styledItem has children property")
            styledItem.children.forEach((styledChild) => {
              if (styledChild.highlight) {
                console.log("styledChild has highlight property")
                // 首先尝试匹配 order
                const matchingUnstyledValue = Object.values(newUnstyledContent).find(
                  (unstyledValue) => unstyledValue.meta.order === styledValue.meta.order
                );
                console.log("matchingUnstyledValue: ", matchingUnstyledValue);

                if (matchingUnstyledValue && matchingUnstyledValue.value) {
                  console.log("500");
                  matchingUnstyledValue.value.forEach((unstyledItem) => {
                    if (unstyledItem.children) {
                      console.log("503");
                      unstyledItem.children.forEach((unstyledChild) => {
                        if (unstyledChild.text === styledChild.text) {
                          console.log("506");
                          unstyledChild.highlight = styledChild.highlight;
                        }
                      });
                    }
                  });
                } else {
                  console.log("513");
                  // 如果无法匹配 order，回退到之前的方法
                  Object.values(newUnstyledContent).forEach((unstyledValue) => {
                    console.log("516");
                    if (unstyledValue.value) {
                      console.log("518");
                      unstyledValue.value.forEach((unstyledItem) => {
                        console.log("520");
                        if (unstyledItem.children) {
                          console.log("522");
                          unstyledItem.children.forEach((unstyledChild) => {
                            if (unstyledChild.text === styledChild.text) {
                              console.log("525");
                              unstyledChild.highlight = styledChild.highlight;
                            }
                          });
                        }
                      });
                    }
                  });
                }
              }
            });
          }
        });
      }
    });
    return newUnstyledContent;  // 返回同步后的内容
  };
  
  return syncHighlights();  // 执行同步并返回结果
}