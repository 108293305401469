import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from 'lucide-react';

export function CustomSelectOption({ options }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const selectRef = useRef(null);

  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // 同步 i18n 的語言設置到 selectedOption
    const currentLang = i18n.language;
    let displayLang;
    switch (currentLang) {
      case 'tc':
        displayLang = '繁體中文';
        break;
      case 'cn':
        displayLang = '简体中文';
        break;
      default:
        displayLang = 'English';
    }
    setSelectedOption(displayLang);
  }, [i18n.language]);

  const toggleSelect = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (['繁體中文', '简体中文', 'English'].includes(option)) {
      let lng;
      if (option === '繁體中文') {
        lng = 'tc';
      } else if (option === '简体中文') {
        lng = 'cn';
      } else {
        lng = 'en';
      }
      changeLanguage(lng);
      console.log(lng)
    }
  };

  return (
    <div className="relative w-64" ref={selectRef}>
      <div
        className="bg-white border border-gray-300 rounded-md p-2 flex justify-between items-center cursor-pointer"
        onClick={toggleSelect}
      >
        <span>{selectedOption}</span>
        <ChevronDown className="w-5 h-5 text-gray-500" />
      </div>
      {isOpen && (
        <ul className="absolute w-full bg-white shadow-lg max-h-60 overflow-auto z-10">
          {options.map((option, index) => (
            <li
              key={index}
              className="p-2 hover:bg-[#DDF3F4] cursor-pointer"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export function CustomRadioGroup({ options, name, onChange, selectedValue, allowDeselect = false }) {
  const handleChange = (option) => {
    if (allowDeselect && selectedValue === option) {
      onChange(''); // Deselect
    } else {
      onChange(option); // Normal select
    }
  };

  return (
    <div className="flex mt-[20px]">
      {options.map((option) => (
        <label key={option} className="flex items-center cursor-pointer me-[20px]">
          <input
            type="radio"
            name={name}
            value={option}
            checked={selectedValue === option}
            onChange={() => handleChange(option)}
            className="hidden"
          />
          <span className="w-[20px] h-[20px] inline-block me-[16px] rounded-full border border-grey flex-no-shrink"></span>
          {option}
        </label>
      ))}
      <style jsx>{`
        input[type="radio"] + span {
          border: 1px solid #c9c9c9;
          transition: border-color 0.3s;
        }
        input[type="radio"]:checked + span {
          border: 5px solid #07BBD3;
          background-color: white;
        }
      `}</style>
    </div>
  );
};