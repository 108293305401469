import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MoreHorizontal, X, Trash2 } from 'lucide-react';

import CustomScrollbar from './customScrollbar';
import NewProjectService from '../service/project-service';
// import { useDeleteProject } from '../utils/customHooks/useDeleteProject';

import logo from "../assets/images/sideBar/logo.png";
import addBtn from "../assets/images/sideBar/addBtn.png";

import style from "./sideBar.module.scss";

export default function SideBar({ content }) {
    const [data, setData] = useState([]);
    const [deletedItem, setDeletedItem] = useState(null);
    const [showUndo, setShowUndo] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [showMenu, setShowMenu] = useState(false);
    const scrollContainerRef = useRef(null);
    const undoTimeoutRef = useRef(null);
    const Navigate = useNavigate();
    const { id } = useParams();
    // const { showUndo, handleDelete, handleUndo, setShowUndo, isPending } = useDeleteProject(setData);

    const scrollUp = () => {
        const container = scrollContainerRef.current;
        if (container) {
            container.scrollBy({ top: -100, behavior: 'smooth' });
        }
    };

    const scrollDown = () => {
        const container = scrollContainerRef.current;
        if (container) {
            container.scrollBy({ top: 100, behavior: 'smooth' });
        }
    };

    const getAllProjects = async () => {
        try {
            const res = await NewProjectService.search();
            setData(res.data.data);
        } catch (e) {
            console.log(e);
        }
    }

    // 獲取 projects 後端 DB 資料 
    useEffect(() => {
        // if (id === "new") return;
        getAllProjects();
    }, [id]);
  
    const handleDelete = (item) => {
        setDeletedItem(item);
        setData(data.filter(i => i.id !== item.id));
        setShowUndo(true);
        setShowMenu(false);

        if (undoTimeoutRef.current) {
            clearTimeout(undoTimeoutRef.current);
        }

        undoTimeoutRef.current = setTimeout(async () => {
            try {
                await NewProjectService.delete(item.id);
                setShowUndo(false);
                setDeletedItem(null);
                getAllProjects();
            } catch (e) {
                console.error('Failed to delete project:', e);
                // Optionally restore the item if deletion fails
                handleUndo();
            }
        }, 7000);
    };

    const handleUndo = () => {
        if (deletedItem) {
            setData(prevData => [...prevData, deletedItem].sort((a, b) => b.id - a.id));
            setShowUndo(false);
            setDeletedItem(null);
            if (undoTimeoutRef.current) {
                clearTimeout(undoTimeoutRef.current);
            }
        }
    };

    const handleDeleteClick = (item) => {
        handleDelete(item, data);
        setShowMenu(false);
    };

    const handleMoreClick = (e, item) => {
        e.stopPropagation();
        const rect = e.currentTarget.getBoundingClientRect();
        setMenuPosition({ x: rect.right, y: rect.top });
        setActiveItem(item.id);
        setShowMenu(true);
    };

    useEffect(() => {
        const handleClickOutside = () => {
            setShowMenu(false);
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    return (
        <div className={`py-[24px] ps-[8px] bg-[#F9FAFB] h-screen relative`}>
            <div className="mx-[6px] mb-[8px] fixed">
                <img src={logo} alt="Logo"></img>
            </div>
            <div className="fixed mt-[35px]">
                {/* <button 
                    onClick={scrollUp}
                    className="absolute right-0 top-0 z-10 w-[18px] h-[18px] flex items-center justify-center bg-[#F9FAFB] transition-colors"
                >
                    <div className={style.upTriangle}></div>
                </button> */}
                <CustomScrollbar>
                    <div 
                        ref={scrollContainerRef}
                        className={`py-10 ${style.textsBlock}`}
                    >
                        <p className='fixed bg-[#F9FAFB] pb-[8px] z-2'>Project List</p>
                        <ul className='mt-[44px] relative'>
                            {data.map((item) => (
                                <li 
                                    key={item.id} 
                                    className='cursor-pointer group flex items-center justify-between px-2 py-1 hover:bg-gray-200 rounded'
                                    onClick={() => Navigate(`/document/${item.id}`)}
                                >
                                    <span>{item?.title.replace(/<[^>]+>/g, '')}</span>
                                    <button 
                                        className="opacity-0 group-hover:opacity-100 transition-opacity"
                                        onClick={(e) => handleMoreClick(e, item)}
                                    >
                                        <MoreHorizontal size={16} />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </CustomScrollbar>
                {/* <button 
                    onClick={scrollDown}
                    className="absolute right-0 bottom-0 z-10 w-[18px] h-[18px] flex items-center justify-center bg-[#F9FAFB] transition-colors"
                >
                    <div className={style.downTriangle}></div>
                </button> */}
            </div>
            <button type="button" className='absolute bottom-4 end-6'>
                <img src={addBtn} alt="create new project" onClick={() => Navigate("/document/new")}></img>
            </button>

            {/* Context Menu */}
            {showMenu && (
                <div 
                    className="absolute bg-white shadow-lg rounded-md py-1 z-50"
                    style={{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px` }}
                >
                    <button 
                        className="w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100 flex items-center"
                        onClick={() => handleDelete(data.find(item => item.id === activeItem))}
                        // onClick={() => handleDeleteClick(data.find(item => item.id === activeItem))}
                    >
                        Delete
                        <Trash2 size={16} />
                    </button>
                </div>
            )}

            {/* Undo Toast */}
            {showUndo && (
                <div className="fixed bottom-4 left-1/4 bg-gray-700 text-white px-4 py-2 rounded-md flex items-center gap-4">
                    <span>Project deleted</span>
                    <button 
                        onClick={handleUndo}
                        className="text-blue-300 hover:text-blue-200 underline"
                        // disabled={isPending}
                    >
                        Undo
                    </button>
                    <button 
                        onClick={() => setShowUndo(false)}
                        className="text-gray-400 hover:text-gray-300"
                    >
                        <X size={16} />
                    </button>
                </div>
            )}
        </div>
    );
}