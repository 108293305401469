import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../AuthContext";

export default function ProtectedRoute({ children }) {
    const { currentUser, loading } = useAuth();
    const location = useLocation();

    // 如果還在加載中，顯示載入畫面
    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
            </div>
        );
    }

    // 加載完成後，如果沒有用戶，重定向到登入頁面
    if (!currentUser) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // 如果有用戶，顯示受保護的內容
    return children;
}