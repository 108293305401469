import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';

import ChatBotComponent from './components/chatBotComponent';
import VisualizationComponent from './components/visualizationComponent';
import Login from './components/login';
import ProtectedRoute from './components/protectedRoute';
// import Document from './components/document';
import Document from './components/document_TEST';
import Setting from './components/setting';
import File from './components/file';
import Help from './components/help';

import { AuthProvider, useAuth } from './AuthContext';

import "./App.css";

function AppRoutes() {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-x-gray-600"></div>
      </div>
    );
  }

  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route 
        exact path="/" 
        element={
          <ProtectedRoute isLoggedIn={currentUser}>
            <Document />
          </ProtectedRoute>
        } 
      />
      <Route 
        exact path="/document/:id" 
        element={
          <ProtectedRoute isLoggedIn={currentUser}>
            <Document />
          </ProtectedRoute>
        } 
      />
      <Route 
        exact path="/setting" 
        element={
          <ProtectedRoute isLoggedIn={currentUser}>
            <Setting />
          </ProtectedRoute>
        } 
      />
      <Route 
        exact path="/file" 
        element={
          <ProtectedRoute isLoggedIn={currentUser}>
            <File />
          </ProtectedRoute>
        } 
      />
      <Route 
        exact path="/help" 
        element={
          <ProtectedRoute isLoggedIn={currentUser}>
            <Help />
          </ProtectedRoute>
        } 
      />
    </Routes>
  );
}

function App() {
  // const [qid, setQid] = useState("");
  // const [graphType, setGraphType] = useState("");
  
  return (
    <div className="App flex justify-center">
      <div className='w-full min-h-screen flex justify-center items-center'>
        <AuthProvider>
          <I18nextProvider i18n={i18n}>
            <AppRoutes />
          </I18nextProvider>
        </AuthProvider>
      </div>
    </div>
  );
}

export default App;