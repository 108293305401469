import axios from "axios";
const BASIC_API = process.env.REACT_APP_BASIC_API + '/Project';
console.log(BASIC_API);

class ProjectService {
    create(requestBody) {
        console.log(requestBody);
        let token;
        JSON.parse(localStorage.getItem("biodnd_ai_user"))
        ? token = JSON.parse(localStorage.getItem("biodnd_ai_user")).token
        : token = "";

        return axios.post(`${BASIC_API}`,
            requestBody, {
            headers: {Authorization: `Bearer ${token}`}
        });
    }

    getDetails(id) {
        let token;
        JSON.parse(localStorage.getItem("biodnd_ai_user"))
        ? token = JSON.parse(localStorage.getItem("biodnd_ai_user")).token
        : token = "";
        // console.log(`token: ${token}`);

        return axios.get(`${BASIC_API}/${id}`, {
            headers: {Authorization: `Bearer ${token}`}
        });
    }

    async search(search = "", isReturnAllDataAndNoPage = true, targetPage = 1, showCount = 10) {
        let token;
        try {
            const userData = JSON.parse(localStorage.getItem("biodnd_ai_user"));
            token = userData ? userData.token : "";
        } catch (e) {
            token = "";
        }

        try {
            const response = await axios.post(`${BASIC_API}/Search`, {
                search,
                "pageRequestParameter": {
                    isReturnAllDataAndNoPage,
                    targetPage,
                    showCount
                }
            }, {headers: {Authorization: `Bearer ${token}`}});
            
            return response;
        } catch (error) {
            console.error('Search API error:', error);
            // 返回一個符合預期格式的錯誤響應
            return { data: { data: [] } };
        }
    }

    update(id, requestBody) {
        let token;
        JSON.parse(localStorage.getItem("biodnd_ai_user"))
        ? token = JSON.parse(localStorage.getItem("biodnd_ai_user")).token
        : token = "";

        return axios.put(`${BASIC_API}/${id}`,
            requestBody, {
            headers: {Authorization: `Bearer ${token}`}
        });
    }

    delete(id) {
        let token;
        JSON.parse(localStorage.getItem("biodnd_ai_user"))
        ? token = JSON.parse(localStorage.getItem("biodnd_ai_user")).token
        : token = "";

        return axios.delete(`${BASIC_API}/${id}`, {
            headers: {Authorization: `Bearer ${token}`}
        });
    }
}

const NewProjectService = new ProjectService();
export default NewProjectService;