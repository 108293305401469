import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { useForm } from "react-hook-form";

import { useAuth } from "../AuthContext";
import useApiErrorHandler from "../utils/customHooks/useApiErrorHandler";

import logo from "../assets/images/login/logo.png";
import emailIcon from "../assets/images/login/email.png";
import passwordIcon from "../assets/images/login/password.png";
import visibilityIcon from "../assets/images/login/visibility.png";
import visibilityOffIcon from "../assets/images/login/visibility-off.png";
import google from "../assets/images/login/google.png";
import facebook from "../assets/images/login/facebook.png";
import apple from "../assets/images/login/apple.png";

import style from "./login.module.scss";

const BASIC_API = process.env.REACT_APP_BASIC_API;

const Button = styled.button`
    color: white;
    padding: 16px 0;
    width: 100%;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function Login() {
    const { currentUser, setCurrentUser } = useAuth();
    const [ isPasswordVisible, setIsPasswordVisible ] = useState(false);
    const { handleApiCall, loading } = useApiErrorHandler();
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm();
    const Navigate = useNavigate();

    const onSubmit = async (data) => {
        console.log(data);
        if (!data.email.trim() || !data.password.trim()) return;
        try {
            const res = await handleApiCall(() => 
                axios.post(`${BASIC_API}/Account/MemberLogin`, {
                    "Email": data.email,
                    "PKey": data.password
                })
            );
            const { token, name, email } = res.data;
            setCurrentUser({ token, name, email });
            localStorage.setItem("biodnd_ai_user", JSON.stringify({ token, name, email }));
            Navigate("/document/1");
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (currentUser) {
            Navigate("/document/1");
        }
    }, []);

    return (
        <>
            {!currentUser && (
                <div className="container h-screen flex items-center relative">
                    <div className='grid grid-cols-4 lg:grid-cols-12 gap-x-[30px] w-full h-fit'>
                        <div className="col-span-4 lg:col-span-8 lg:col-start-3 flex flex-col items-center">
                            <div>
                                <img src={logo} alt="BIODND logo"></img>
                                <p className="text-nowrap mt-[20px] text-center">Don't have an account? <span className="underline text-primary">Sign up</span></p>
                            </div>
                            <div className="w-full flex justify-between mt-[96px]">
                                <form className={`w-[47%] ${style.form} pe-[5%]`} onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <label htmlFor="email" className="w-full block text-start">Email</label>
                                        <div className={`${style.inputIconGroup}`}>
                                            <img src={emailIcon} alt="email"></img>
                                            <input placeholder="Enter your email address" id="email" type="email" className={`w-full ${errors.name ? `border-danger` : ""}`} {...register("email", { required: "Email is required." })} />
                                        </div>
                                        {errors.email && <span className="text-red-500 w-full block text-start">{errors.email.message}</span>}
                                    </div>
                                    <div className="mt-[38px]">
                                        <label htmlFor="password" className="w-full block text-start">Password</label>
                                        <div className={`${style.inputIconGroup}`}>
                                            <img src={passwordIcon} alt="password"></img>
                                            <input placeholder="Enter your password" id="password" type={isPasswordVisible ? "text" : "password"} className={`w-4/5 ${errors.name ? `border-danger` : ""}`} {...register("password", { required: "Password is required." })} />
                                            <div className="p-1 ms-0.5">
                                                <img src={isPasswordVisible ? visibilityOffIcon : visibilityIcon} alt="password visibility" className="cursor-pointer" onClick={() => setIsPasswordVisible(!isPasswordVisible)}></img>
                                            </div>
                                        </div>
                                        {errors.password && <span className="text-red-500 w-full block text-start">{errors.password.message}</span>}
                                    </div>
                                    <div className="mt-[14px] flex justify-between">
                                        <div>
                                            <input type="checkbox" />
                                            <span className="ms-[6px]">Remember me</span>
                                        </div>
                                        <button className="btn">Forget Password?</button>
                                    </div>
                                    <Button type="submit" className={`${isSubmitting ? "bg-primary/50 text-white/70" : "bg-primary text-white hover:bg-[#07bbd3]/90"} mt-[60px]`} disabled={isSubmitting}>{isSubmitting ? "Logging in ..." : "Log in"}</Button>
                                </form>
                                <div className="w-[6%] flex flex-col items-center relative">
                                    <div className="w-[1px] h-full bg-secondary"></div>
                                    <span className="absolute top-1/2 -translate-y-[70%] bg-white block h-fit py-[32px]">or</span>
                                </div>
                                <div className="w-[47%] ps-[5%]">
                                    <div className="w-full flex flex-col h-full justify-center">
                                        <Button className="bg-[#EB4335]">
                                            <img src={google} alt="google" className="me-[24px]"></img>
                                            Sign in with Google
                                        </Button>
                                        <Button className="bg-[#036BE3] mt-[22px]">
                                            <img src={facebook} alt="facebook" className="me-[24px]"></img>
                                            Sign in with Facebook</Button>
                                        <Button className="bg-black mt-[22px]">
                                            <img src={apple} alt="apple" className="me-[42px]"></img>
                                            Sign in with Apple</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="absolute bottom-[50px] end-[80px] text-[#69747F]">隱私權政策｜條款</button>
                </div>
            )}
        </>
    )
}