import axios from "axios";
const BASIC_API = process.env.REACT_APP_BASIC_API + '/GcpSearchApi';
console.log(BASIC_API);

class GCPSearchService {
    getDetails(queryString) {
        let token;
        JSON.parse(localStorage.getItem("biodnd_ai_user"))
        ? token = JSON.parse(localStorage.getItem("biodnd_ai_user")).token
        : token = "";
        // console.log(`token: ${token}`);

        return axios.post(`${BASIC_API}/BusinessReportAgentSearch`, { queryString } , {
            headers: {Authorization: `Bearer ${token}`}
        });
    }
}

const NewGCPSearchService = new GCPSearchService();
export default NewGCPSearchService;